<template>
  <div class="container">
    <div class="leftItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/wxc63Zrs9TU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="rightItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/3G2cO833Bfc"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="container">
    <div class="leftItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/qGGRxSlefWs"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="rightItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/dLia2RJ0C8I"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="container">
    <div class="leftItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/DXCH6eAZHy8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="rightItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/5Ind6B43a9c"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="container">
    <div class="leftItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/fXg_h9A83jk"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="rightItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/4ht_kJeLctI"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="container">
    <div class="leftItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/tjWfjbDJoXU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="rightItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/Z9BT7dgeLZg"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="container">
    <div class="leftItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/QTJKgNkh3YU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="rightItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/XC5_cioiSYI"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="container">
    <div class="leftItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/DzvwPFg72gQ"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="rightItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/xs1MzhNIem8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <div class="container">
    <div class="leftItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/sWGoEY6Cz7I"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div class="rightItem">
      <iframe
        class="youtube"
        src="https://www.youtube.com/embed/I8M_BpOuOV8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <br />
  ועוד הפקות רבות אחרות ...
</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Productions",
  components: {},
  methods: {},
  created() {},
});
</script>

<style scoped>
.container {
  display: flex; /* or inline-flex */
  flex-direction: row;
  justify-content: center;
  padding: 15px;
}

.leftItem {
  width: 50%;  
  text-align: left;
  padding: 5px;
}

.rightItem {
  width: 50%;
  text-align: right;
  padding: 5px;
}

.youtube {
  width: 600px;
  height: 350px;
  border: 1px solid white;
}

@media (max-width: 700px) {
  .youtube {
    max-width: 100%;
    height: auto;
    border: 1px solid white;
  }
}
</style>