<template>
  <div class="container">
    <div>
      מחפש לימודי הפקה מוסיקלית? <br />
      הפקה וסאונד עם התוכנות החדשות ביותר ?<br />
      לשלב לימודים עיוניים עם הפקה מוסיקלית בפועל ?<br />
      <br />
      מתן שיעורים פרטיים בסטודיו מאובזר, בתוכנות : Cubase או Studio One - החל
      מהשלב הבסיסי של בניית הסקיצה הקלטת גייד עם קלידים או גיטרה, להקלטות מלאות
      של כלים נוספים, העמדת מיקרופונים ועד למיקס / מאסטרינג של השיר.
      <br />
      <br />
      צור קשר לפרטים נוספים / התאמות כמובן ועוד... 😊
      <br />
      <br />
    </div>
    <div>
      <img class="img" src="../assets/Cubase.png" />
      <br />
      <br />
    </div>
    <div>
      <img class="img" src="../assets/StudioOne.jpg" />
    </div>
  </div>
</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Learn",
  components: {},
  methods: {},
  created() {},
});
</script>

<style scoped>
.container {
  display: flex; /* or inline-flex */
  width: 95%;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  margin: 50px;
  border-radius: 25px;
  margin: auto;
  margin-top: 10px;
  text-align: right;
  font-size: 2rem;
}

.img {
  max-width: 400px;    
}

.leftItem {
  display: inline; /* or inline-flex */
  margin: auto;
}

.rightItem {
  text-align: right;
  vertical-align: middle;
  margin: auto;
  font-size: 3rem;
}

@media (max-width: 700px) {
  .container {
    font-size: 1rem;
  }

  .img {
    max-width: 200px;    
  }
}
</style>
