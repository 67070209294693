<template>
  <div class="container">
    <div class="leftItem">
      <img class="img1" src="../assets/Nofar.jpg" />
    </div>
    <div class="rightItem">
      <h1>נופר מרגליות / זמרת-יוצרת</h1>
      <br />אני רוצה להמליץ על אודי אזולאי, פשוט תענוג לעבוד איתו! אודי מוזיקאי
      מדהים, הנגינה שלו יפיפייה , הרעיונות המוזיקליים שלו מעולים! הוא יכול לקבל
      שיר פשוט ויפה ולהפוך אותו לשיר מיוחד ומרגש! שלא תאמינו שמדובר באותו שיר!
      אך מעבר למוזיקליות המקצועית אודי מלא ברגש, סבלני לאמנים שעובדים איתו,
      מקשיב, פתוח ומכבד כל רעיון מוזיקלי נעים כל כך לעבוד איתו אודי נותן הרגשה
      של בית יחד עם מקצועיות ברמה הגבוהה ביותר! לא סתם חזרתי לאודי שוב ושוב...
      ממליצה בחום ובאהבה הרבה!
    </div>
  </div>
  <div class="container">
    <div class="leftItem">
      <img class="img1" src="../assets/Idan.jpg" />
    </div>
    <div class="rightItem">
      <h1>עידן צ'או / זמר יוצר</h1>
      <br />
      הקלטתי אצל אודי אלבום שלם, ואני יכול להגיד שמעבר לזה שאודי בן אדם כייפי
      ומעניין, אודי מקצוען אמיתי ואפשר לראות שהוא עובד מתוך אהבה למה שהוא עושה
      ויתקן עד שגם הוא וגם אני נהיה מרוצים. לא סתם בחרתי להוציא את השירים
      הראשונים שלי לעולם איתו, שאת רובם הקלטתי אצלו, ואת כולם הוא מיקסס והוסיף
      גוון מרגש משלו לשירים. ממליץ בחום, ואין לי ספק שאני הולך לעבוד איתו עוד
      הרבה!
    </div>
  </div>

    <div class="container">
    <div class="leftItem">
      <img class="img1" src="../assets/Shmulik.jpg" />
    </div>
    <div class="rightItem">
      <h1>שמוליק ברדוש / זמר-יוצר-מלחין</h1>
      <br />ההפקות הטובות ביותר שזכיתי להן עד היום בעיניי היו אצל אודי אזולאי באולפן.
גם מבחינת העיבוד, גם מבחינת ההקפדה על רמת ההקלטה וגם מבחינת התוצאה הסופית.
הייתי נוסע במיוחד מהמרכז בשביל להקליט וזה היה שווה כל רגע.
    </div>
  </div>

      <div class="container">
    <div class="leftItem">
      <img class="img1" src="../assets/Yativ.jpg" />
    </div>
    <div class="rightItem">
      <h1>יתיב רייס / זמר-יוצר</h1>
      <br />אצל אודי הפקתי ארבעה שירים , כל שיר בסגנון , כלים  והפקות שונות  .האולפן מאובזר ויפייפה ממוקם בקיבוץ אל מול שדות ירוקים  . אצל אודי קבלתי יחס אישי מקצועיות סבלנות ודיאלוג מתמיד עד לתוצאה הסופית . ממליץ בחום למי שמחפש תהליך ומוצר מושלם  , יתיב רייס זמר ויוצר .
    </div>
  </div>

</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Recommendation",
  components: {},
  methods: {},
});
</script>

<style scoped>
.container {
  display: flex; /* or inline-flex */
  flex-direction: row;
  justify-content: center;
  padding: 15px;
  margin: 50px;
  background-color: rgb(152, 153, 153);
  border-radius: 25px;
}

.img1 {
  max-width: 200px;
  border-radius: 50%;
  box-shadow: 2px 2px 14px gray;
}

.leftItem {
  display: inline; /* or inline-flex */
  margin: auto;
}

.rightItem {
  text-align: right;
  vertical-align: middle;
  margin: auto;
  padding: 20px;
  font-size: 2rem;
}

@media (max-width: 700px) {
  .container {
    padding: 3px;
    margin: 10px;
    align-items: stretch;
  }

  .img1 {
    max-width: 100px;
    border-radius: 50%;
    box-shadow: 2px 2px 14px gray;
  }

  h1 {
    font-size: 1.2rem;
  }

  .rightItem {
    padding: 5px;
    font-size: 1rem;
  }

  .leftItem {
    display: inline-table; /* or inline-flex */
    margin-top: 5px;
  }
}
</style>