<template>
  <div class="container">
    <div class="leftItem">
      <input type="text" ref="fullName" class="text" placeholder="שם מלא" />
      <br />
      <input type="text" ref="email" class="text" placeholder="מייל" />
      <br />
      <input type="text" ref="phone" class="text" placeholder="טלפון" />
      <br />
      <textarea
        ref="questions"
        class="textarea"
        placeholder="שאלות/בקשות..."
      ></textarea>
      <br />
      <button id="btn" class="btn" @click="submit">שלח</button>
    </div>
    <div class="rightItem">
      אולפן הקלטות ביסעור
      <br />
      054-7886141
      <br />
      uazulay@gmail.com
    </div>
  </div>
</template>


<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Contact",
  components: {},
  methods: {
    submit() {
      var fullName = this.$refs["fullName"].value;
      var email = this.$refs["email"].value;
      var phone = this.$refs["phone"].value;
      var questions = this.$refs["questions"].value;
      if (fullName == "" || email == "") {
        alert("נא להכניס שם + אמייל!");
        return;
      }

      const data = { body: "fullName :" + fullName + "<br>" +  "email :" + email + "<br>" + "phone :" + phone + "<br>" + "questions :" + questions + "<br>"};

      fetch("https://api.ataba.live/SendMail", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            alert("ההודעה נשלחה בהצלחה!");
            console.log(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
  created() {},
});
</script>

<style scoped>
.container {
  display: flex; /* or inline-flex */
  width: 70%;
  flex-direction: row;
  justify-content: center;
  padding: 15px;
  margin: 50px;
  background-color: rgb(152, 153, 153);
  border-radius: 25px;
  margin: auto;
  margin-top: 10px;
}

.text {
  width: 500px;
  height: 50px;
  font-size: 2rem;
  border-radius: 10px;
}

.btn {
  width: 500px;
  height: 50px;
  font-size: 2rem;
  border-radius: 10px;
}

.textarea {
  width: 500px;
  height: 300px;
  font-size: 2rem;
  border-radius: 10px;
}

.img1 {
  max-width: 200px;
  border-radius: 50%;
  box-shadow: 2px 2px 14px gray;
}

.leftItem {
  display: inline; /* or inline-flex */
  margin: auto;
}

.rightItem {
  text-align: right;
  vertical-align: middle;
  margin: auto;
  font-size: 3rem;
}

@media (max-width: 700px) {
  .container {
    width: 90%;
    display: flex; /* or inline-flex */
    flex-direction: column;
    justify-content: center;
    background-color: rgb(152, 153, 153);
    border-radius: 25px;
  }

  .text {
    width: 300px;
    height: 30px;
    font-size: 1rem;
    border-radius: 10px;
  }

  .btn {
    width: 300px;
    height: 50px;
    font-size: 1.5rem;
    border-radius: 10px;
  }

  .textarea {
    width: 300px;
    height: 300px;
    font-size: 1.5rem;
    border-radius: 10px;
  }

  h1 {
    font-size: 1.2rem;
  }

  .rightItem {
    padding: 5px;
    font-size: 2rem;
  }

  .leftItem {
    display: inline-table; /* or inline-flex */
    margin-top: 5px;
  }
}
</style>
